import { Link } from "gatsby";
import React from "react";
import "./exito.scss";

export const ExitoPage: React.FC = () => {
  return (
    <div className="exito-page">
      <div className="container">
        <div className="exito-title">¡Gracias!</div>
        <h1>Tus datos han sido enviado con éxito</h1>
        <Link to="/" className="button">
          Volver al inicio
        </Link>
      </div>
    </div>
  );
};
